* {
  box-sizing: border-box !important;
}

*:not(input) {
  user-select: none !important;
}

html,
body,
#root {
  height: 100%;
}
